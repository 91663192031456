<template>
  <div>
    <el-dialog
      title="物资明细"
      :visible.sync="visible"
      append-to-body
      v-dialogDrag
      width="80%"
      :close-on-click-modal="false"
    >
      <el-form size="mini" :inline="true">
        <el-form-item label="仓库">
          <base-select info="Store" v-model="storeId"></base-select>
        </el-form-item>
        <el-form-item label="过滤">
          <el-input v-model="filter" placeholder="输入关键字搜索" clearable></el-input>
        </el-form-item>
        <el-form-item label="总条数">
          <el-tag type="success">{{totalCount}}</el-tag>
        </el-form-item>
        <el-form-item>
          <el-button type="info" @click="save">保存关闭</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="array" stripe border v-loading="loading">
        <el-table-column type="index"></el-table-column>
        <el-table-column label="编号" prop="goodsCode" show-overflow-tooltip></el-table-column>
        <el-table-column label="名称" prop="goodsName" show-overflow-tooltip></el-table-column>
        <el-table-column label="规格" prop="goodsSpec" show-overflow-tooltip></el-table-column>
        <el-table-column label="单位" prop="goodsUnit" show-overflow-tooltip></el-table-column>
        <el-table-column label="可用数量" prop="quantityCanUse" show-overflow-tooltip>
          <template slot-scope="scope">{{scope.row.quantityCanUse|thousands}}</template>
        </el-table-column>
        <el-table-column label="使用数量">
          <template slot-scope="scope">
            <el-input type="number" v-model="scope.row.quantity" @mousewheel.native.prevent></el-input>
          </template>
        </el-table-column>
        <el-table-column label="备注">
          <template slot-scope="scope">
            <el-input v-model="scope.row.remark"></el-input>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="total, prev, pager, next, jumper"
        :current-page.sync="pageIndex"
        :page-size="10"
        :total="pageTotal"
      ></el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import BaseSelect from "@/components/BaseSelect.vue";
import guid from "@/common/guid.js";
import setName from "@/common/setName";
export default {
  components: {
    BaseSelect
  },
  props: ["item"],
  data() {
    return {
      visible: false,
      loading: false,
      storeId: "",
      filter: "",
      arrayTotal: [],
      pageIndex: 1,
      pageTotal: 0
    };
  },
  watch: {
    item: {
      handler: function(params) {
        this.storeId = "";
        this.filter = "";
        this.visible = true;
      },
      deep: true
    },
    storeId: function(v) {
      this.getData();
    }
  },
  computed: {
    array() {
      let temp = this.arrayTotal;
      let text = this.filter;
      if (text) {
        temp = temp.filter(item => {
          if (
            item.goodsName.indexOf(text) >= 0 ||
            item.pinYin.indexOf(text.toLowerCase()) >= 0 ||
            item.goodsCode.indexOf(text) >= 0 ||
            (item.goodsSpec && item.goodsSpec.indexOf(text) >= 0)
          ) {
            return true;
          }
        });
      }
      this.pageTotal = temp.length;
      let start = (this.pageIndex - 1) * 10;
      return temp.slice(start, start + 10);
    },
    totalCount() {
      let res = 0;
      this.arrayTotal.forEach(item => {
        let quantity = Number(item.quantity);
        if (quantity != 0) {
          res++;
        }
      });
      return res;
    }
  },
  methods: {
    getData() {
      if (this.storeId) {
        this.loading = true;
        this.$get("Goods/AllGoodsCanUse", {
          locationId: this.storeId
        })
          .then(r => {
            r.forEach(item => {
              item.quantity = null;
              item.remark = "";
            });
            this.arrayTotal = r;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.arrayTotal = [];
      }
    },
    save() {
      let res = [];
      let error = "";
      this.arrayTotal.forEach(item => {
        let quantity = Number(item.quantity);
        if (item.quantityCanUse < quantity) {
          error = `物资编号【${item.goodsCode}】不可大于可用数量！`;
        }
        if (quantity > 0) {
          let r = {
            id: guid(),
            storeId: this.storeId,
            goodsId: item.goodsId,
            goodsCode: item.goodsCode,
            goodsName: item.goodsName,
            goodsSpec: item.goodsSpec,
            goodsUnit: item.goodsUnit,
            quantity: quantity,
            remark: item.remark
          };
          setName("Store", r, "storeId", "storeName");
          res.push(r);
        }
      });
      if (error) {
        this.$message.error(error);
        return;
      }
      if (res.length == 0) {
        this.$message.error("请选择物资明细");
        return;
      }
      this.$emit("success", res);
      this.visible = false;
    }
  }
};
</script>

<style>
</style>