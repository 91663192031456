<template>
  <div>
    <el-dialog
      title="物资明细"
      :visible.sync="visible"
      append-to-body
      v-dialogDrag
      width="60%"
      :close-on-click-modal="false"
    >
      <el-form :model="form" status-icon :rules="rules" ref="form" label-width="60px">
        <el-row>
          <el-col :span="6">
            <el-form-item label="仓库" prop="storeId">
              <base-select @input="setGoodsInfo" info="Store" v-model="form.storeId"></base-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="物资" prop="goodsId">
              <base-select @input="setGoodsInfo" info="Goods" v-model="form.goodsId"></base-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="规格">
              <el-input :value="form.goodsSpec" readonly="readonly" placeholder="规格"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="单位">
              <el-input :value="form.goodsUnit" readonly="readonly" placeholder="单位"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="数量" prop="quantity">
              <el-input
                type="number"
                v-model="form.quantity"
                placeholder="数量"
                clearable
                @mousewheel.native.prevent
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item label-width="80px" label="可用数量" v-if="quantityCanUse!=null">
              <el-tag size="mini">{{quantityCanUse}}</el-tag>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="备注">
          <el-input v-model="form.remark" placeholder="备注" type="textarea" :rows="2" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group>
            <el-button type="info" @click="save(false)">保存关闭</el-button>
            <el-button type="success" @click="save(true)">保存新增</el-button>
          </el-button-group>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import guid from "@/common/guid.js";
import BaseSelect from "@/components/BaseSelect.vue";
import setName from "@/common/setName";
export default {
  props: ["item"],
  components: {
    BaseSelect
  },
  data() {
    return {
      visible: false,
      form: {},
      quantityCanUse: null,
      rules: {
        storeId: [this.$g.required],
        goodsId: [this.$g.required],
        quantity: [
          {
            validator: (rule, value, callback) => {
              if (Number(value) <= 0) {
                return callback(new Error("数量必须大于0"));
              }
              if (this.quantityCanUse != null) {
                if (Number(value) > this.quantityCanUse) {
                  return callback(new Error("数量不可大于可用数量"));
                }
              }
              callback();
            },
            trigger: "blur"
          }
        ]
      }
    };
  },
  watch: {
    item: {
      handler: function(row) {
        if (row.id) {
          this.form = { ...row };
        } else {
          this.initForm();
        }
        this.quantityCanUse = null;
        this.visible = true;
      },
      deep: true
    }
  },
  methods: {
    initForm() {
      this.form = {
        id: guid(),
        storeId: "",
        goodsId: "",
        goodsCode: "",
        goodsName: "",
        goodsSpec: "",
        goodsUnit: "",
        quantity: null,
        remark: ""
      };
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    setGoodsInfo() {
      let { storeId, goodsId } = { ...this.form };
      if (goodsId && storeId) {
        this.form.quantity = null;
        this.$get("Goods/GetGoodsCanUse", {
          goodsId,
          locationId: storeId
        }).then(r => {
          this.form.goodsCode = r.goodsCode;
          this.form.goodsName = r.goodsName;
          this.form.goodsSpec = r.goodsSpec;
          this.form.goodsUnit = r.goodsUnit;
          this.quantityCanUse = r.quantityCanUse;
        });
      } else {
        this.form.goodsCode = "";
        this.form.goodsName = "";
        this.form.goodsSpec = "";
        this.form.goodsUnit = "";
        this.form.quantity = null;
        this.quantityCanUse = null;
      }
    },

    save(isContinue) {
      this.$refs["form"].validate(valid => {
        if (!valid) {
          return;
        }
        let res = { ...this.form };
        setName("Store", res, "storeId", "storeName");
        this.$emit("success", res);
        if (isContinue) {
          this.quantityCanUse = null;
          this.initForm();
        } else {
          this.visible = false;
        }
      });
    }
  }
};
</script>

<style>
</style>